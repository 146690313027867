import React, {Component, useState,useEffect} from 'react';
import {form} from "./service/form.services";
import ReviewElement from "./components/ReviewElement";
import InputBox from "./components/InputBox";
import preloader from "./assets/images/pre-loader.png";
import CheckboxBlock from "./components/CheckboxBlock";
import TextArea from "./components/TextArea";
import RadioBlock from "./components/RadioBlock";
import RangeSlider from "./components/RangeSlider";
import {ES_INDEX} from "./config";


function SurveyHolder(props){
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const [data, setData] = useState({attachment:"",backgroundImage:"",questions:[],surveyID:"",surveySubtitle:"",surveyTitle:"",status:''});
    const [inx, setIndex] = useState(0);
    const [loader, setLoader] = useState(false)
    const [sd, setSubmitted] = useState({status:false,submitted:false,message:'',code:''})

    const [isRemarks1, setRemarks1Status] = useState(false)
    const [isRemarks2, setRemarks2Status] = useState(false)
    const [isRemarks3, setRemarks3Status] = useState(false)
    const [mandatoryMsg, setMandatoryMsg] = useState('')

    useEffect(() => {
        setData(props.data)
    }, [props.data]);

    useEffect(() => {
        props.setID(inx)
    }, [inx]);
    function changeToarray(d){
        let sv=d.replaceAll("|",",")
        var nv = sv;
        var nvArray = nv.split(',');
        return nvArray;
    }
    function changeToSlider(d,ix){
        let sv=d.replaceAll("|",",")
        var nv = sv;
        var nvArray = nv.split(',');
        return nvArray[ix];
    }

    function checkRemarks_(nd, n, ans){
        // console.log(nd)

        let ansArr = ans.split(',')


        if(nd.questions[n].remark1 === 'YES'){

            let remark1TriggerArr = nd.questions[n].remark1Trigger.split('|')
            let stat = false

            for(let ai in ansArr){
                if(remark1TriggerArr.indexOf(ansArr[ai]) > -1){
                    stat = true
                    }
                }

            setRemarks1Status(stat)

            }
        else{
            setRemarks1Status(false)
            }

        if(nd.questions[n].remark2 === 'YES'){

            let remark2TriggerArr = nd.questions[n].remark2Trigger.split('|')
            let stat = false

            for(let ai in ansArr){
                if(remark2TriggerArr.indexOf(ansArr[ai]) > -1){
                    stat = true
                    }
                }

            setRemarks2Status(stat)

            }
        else{
            setRemarks2Status(false)
            }
        }

    function checkRemarks(nd, n, ans){
        // console.log(nd)

        let ansArr = ans.split(',')

        if(nd.questions[n].remark1 === 'YES'){

            let remark1TriggerArr = nd.questions[n].remark1Trigger.split('|')


            let stat = false

            for(let ai in ansArr){
                if(remark1TriggerArr.indexOf(ansArr[ai]) > -1){
                    stat = true
                }
            }

            if(!stat){
                nd.questions[n].remark_1=""

            }

            setRemarks1Status(stat)


        }
        else{
            nd.questions[n].remark_1=""

            setRemarks1Status(false)
        }

        if(nd.questions[n].remark2 === 'YES'){

            let remark2TriggerArr = nd.questions[n].remark2Trigger.split('|')

            let stat_2 = false

            for(let ai in ansArr){
                if(remark2TriggerArr.indexOf(ansArr[ai]) > -1){
                    stat_2 = true
                }
            }
            if(!stat_2){
                nd.questions[n].remark_2=""

            }
            setRemarks2Status(stat_2)


        }
        else{
            nd.questions[n].remark_2=""

            setRemarks2Status(false)
        }

        if(nd.questions[n].remark3 === 'YES'){

            let remark3TriggerArr = nd.questions[n].remark3Trigger.split('|')

            let stat_3 = false

            for(let ai in ansArr){
                if(remark3TriggerArr.indexOf(ansArr[ai]) > -1){
                    stat_3 = true
                }
            }
            if(!stat_3){
                nd.questions[n].remark_3=""

            }
            setRemarks3Status(stat_3)


        }
        else{
            nd.questions[n].remark_2=""

            setRemarks3Status(false)
        }
    }


    function checkRemarksStatus(nd, n, ans, rNo){
        // console.log(nd)
         ans= (ans === undefined?"":ans)
        let ansArr = ans.split(',')

        if(rNo === 1){
            if(nd.questions[n].remark1 === 'YES'){

                let remark1TriggerArr = nd.questions[n].remark1Trigger.split('|')

                let stat = false

                for(let ai in ansArr){
                    if(remark1TriggerArr.indexOf(ansArr[ai]) > -1){
                        stat = true
                        }
                    }

                return(stat)

                }
            else{
                return(false)
                }
            }

        if(rNo === 2){
            if(nd.questions[n].remark2 === 'YES'){

                let remark2TriggerArr = nd.questions[n].remark2Trigger.split('|')

                let stat = false

                for(let ai in ansArr){
                    if(remark2TriggerArr.indexOf(ansArr[ai]) > -1){
                        stat = true
                        }
                    }

                return(stat)

                }
            else{
                return(false)
                }
            }

        if(rNo === 3){
            if(nd.questions[n].remark3 === 'YES'){

                let remark3TriggerArr = nd.questions[n].remark3Trigger.split('|')

                let stat = false

                for(let ai in ansArr){
                    if(remark3TriggerArr.indexOf(ansArr[ai]) > -1){
                        stat = true
                        }
                    }

                return(stat)

                }
            else{
                return(false)
                }
            }


        }

    function onTextChange(e, inx){

        setMandatoryMsg('')
        let nd={...data}
        nd.questions[inx]['answer'] = e.target.value
        let ans = e.target.value

        checkRemarks(nd, inx, ans)

        setData(nd)
    }

    function onRemarkTextChange(e, r, inx){
        // console.log(e.target.value)
        setMandatoryMsg('')

        let nd={...data}

        if(r === 1){
            nd.questions[inx]['remark_1'] = e.target.value
            }
        if(r === 2){
            nd.questions[inx]['remark_2'] = e.target.value
            }
        if(r === 3){
            nd.questions[inx]['remark_3'] = e.target.value
            }
// console.log(nd)
        setData(nd)
        // console.log(nd)
        }

    function handleCheckbox(n,v){
        setMandatoryMsg('')
        // console.log(n,v)
        let nd={...data}
        nd.questions[n]['answer'] =v

        checkRemarks(nd, n, v)

        setData(nd)
    }
    function onRemarkHandleCheckbox(n,v, r){
        setMandatoryMsg('')
        // console.log(r)
        let nd={...data}

        if(r === 1){
            nd.questions[n]['remark_1'] = v
            }
        if(r === 2){
            nd.questions[n]['remark_2'] = v
            }
        if(r === 3){
            nd.questions[n]['remark_3'] = v
            }

        setData(nd)
        // console.log(nd)
        }

    function validate(){
        let nd={...data}

        let doproceed = true

        // console.log(nd.questions[inx])

        //---- set default answer value for slider scale component question, if user clicks next without selecting answer
        if(nd.questions[inx].answerType === 'Slider Scale' && nd.questions[inx].mandatory === 'Yes'){
            if(nd.questions[inx].answer === undefined || nd.questions[inx].answer === ''){
                let maxval = changeToSlider(data.questions[inx].answerChoices, 2)
                // let defaultval = Math.round(maxval/2)
                let defaultval = 0
                nd.questions[inx].answer = String(defaultval)
                }
            }
        //----


        if(nd.questions[inx].mandatory === 'Yes'){
            if(nd.questions[inx].answer === undefined || nd.questions[inx].answer === ''){
                doproceed = false
                return doproceed
                }

            let doproceed1 = true
            let doproceed2 = true
            let doproceed3 = true
            let stat1 = false
            let stat2 = false
            let stat3 = false

            if(nd.questions[inx].remark1 === 'YES'){
                let ansArr = nd.questions[inx].answer.split(',')

                let remark1TriggerArr = nd.questions[inx].remark1Trigger.split('|')

                for(let ai in ansArr){
                    if(remark1TriggerArr.indexOf(ansArr[ai]) > -1){
                        stat1 = true
                        }
                    }

                if(stat1 === true){
                    if(nd.questions[inx].remark_1 === undefined || nd.questions[inx].remark_1 === ''){
                        doproceed1 = false
                        }
                    }

                // console.log(1, stat1, doproceed1)

                }
            if(nd.questions[inx].remark2 === 'YES'){

                let ansArr = nd.questions[inx].answer.split(',')

                let remark2TriggerArr = nd.questions[inx].remark2Trigger.split('|')

                for(let ai in ansArr){
                    if(remark2TriggerArr.indexOf(ansArr[ai]) > -1){
                        stat2 = true
                        }
                    }

                if(stat2 === true){
                    if(nd.questions[inx].remark_2 === undefined || nd.questions[inx].remark_2 === ''){
                        doproceed2 = false
                        }
                    }

                // console.log(2, stat2, doproceed2)

                }

            if(nd.questions[inx].remark3 === 'YES'){

                let ansArr = nd.questions[inx].remark_1 === undefined? [] : nd.questions[inx].remark_1.split(',')

                let remark3TriggerArr = nd.questions[inx].remark3Trigger.split('|')

                for(let ai in ansArr){
                    if(remark3TriggerArr.indexOf(ansArr[ai]) > -1){
                        stat3 = true
                        }
                    }

                if(stat3 === true){
                    if(nd.questions[inx].remark_3 === undefined || nd.questions[inx].remark_3 === ''){
                        doproceed3 = false
                        }
                    }

                // console.log(3, stat3, doproceed3)

                }

            let finalDoProceed = true

            if(stat1 === true && doproceed1 === false){
                finalDoProceed = false
                }
            if(stat2 === true && doproceed2 === false && nd.questions[inx].mandatory1 === 'Yes'){
                finalDoProceed = false
                }
            if(stat3 === true && doproceed3 === false && nd.questions[inx].mandatory2 === 'Yes'){
                finalDoProceed = false
                }
            return finalDoProceed

            }
        else{
            return true
            }

        }

    function backClick(){
        setMandatoryMsg('')
        setIndex(inx - 1)
        }
    function nextClick(){
        // console.log(inx)
        let doproceed = validate()

        if(doproceed === false){
            setMandatoryMsg('Kindly share your response to the question to proceed further')
            return
            }
        setIndex(inx + 1)
        setRemarks1Status(false)
        setRemarks2Status(false)
        setRemarks3Status(false)
        }
    function previousClick(){


        setIndex(inx - 1)
        setRemarks1Status(false)
        setRemarks2Status(false)
        setRemarks3Status(false)
    }




    function getUserDetails() {
        const now = new Date();

        // 1) User time
        const userTime = now.toLocaleTimeString();

        // 2) User time zone
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // 3) Date
        const date = now.getDate();

        // 4) Day
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const day = days[now.getDay()];

        // 5) Day Segment
        const hours = now.getHours();
        let daySegment;
        if (hours >= 6 && hours < 12) {
            daySegment = "Morning";
        } else if (hours >= 12 && hours < 18) {
            daySegment = "Afternoon";
        } else if (hours >= 18 && hours < 21) {
            daySegment = "Evening";
        } else {
            daySegment = "Night";
        }

        // 6) Month
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const month = months[now.getMonth()];

        // 7) Month Number
        const monthNumber = now.getMonth() + 1;

        // 8) Year
        const year = now.getFullYear();

        return {
            userTime,
            timeZone,
            date,
            day,
            daySegment,
            month,
            monthNumber,
            year
        };
    }

    function handleSubmit(){

        let doproceed = validate()

        if(doproceed === false){
            setMandatoryMsg('Kindly share your response to the question to proceed further')
            return
            }

        const details = getUserDetails();

        setLoader(true)

        let param={
            token:token,
            data:{
                "index":ES_INDEX,
                 "dataset":data.questions,
                "survey_id": "",
                "campaignId": "",
                "campaigntype": "",
                "uniqueid": "",
                "msgsvcid": "",
                "auuid": "",
                "fname": "",
                "lname": "",
                "aid": "",
                "server": "",
                "payload": "",
                "uid":0

            },
            userTime:details.userTime,
            timeZone:details.timeZone,
            date:details.date,
            day:details.day,
            daySegment:details.daySegment,
            month:details.month,
            monthNumber:details.monthNumber,
            year:details.year

        }


        form.saveData(param).then(function (res) {
            console.log(res.res)
            setLoader(false)
            setSubmitted({status:res.res.status,submitted:true,message:'',code:res.code})
        })
        setIndex('')
    }



    // console.log(data)
    return(
        <>
            {loader &&
            <div className="preLoader">
                <img src={preloader} alt="preloader"/>
            </div>
            }

            {!sd.submitted && token !== null && data.status ==="Success" &&
            <>
                {data.questions[inx] &&
                <div className='qsBlock'>
                    <h4>{data.questions[inx].question}</h4>


                    {data.questions[inx].answerType === "Slider Scale" &&
                    <RangeSlider    type="range" name={inx}
                                    key={inx}
                                    start_text={changeToSlider(data.questions[inx].answerChoices, 0)}
                                    end_text={changeToSlider(data.questions[inx].answerChoices, 1)}
                                    max_val={changeToSlider(data.questions[inx].answerChoices, 2)}
                                    value={data.questions[inx].hasOwnProperty('answer') ? data.questions[inx]['answer'] : ''}
                                    onslideChange={(e) => onTextChange(e, inx)}
                    />



                    // <ReviewElement type="range" name={inx}
                    //                start_text={changeToSlider(data.questions[inx].answerChoices, 0)}
                    //                end_text={changeToSlider(data.questions[inx].answerChoices, 1)}
                    //                max_val={changeToSlider(data.questions[inx].answerChoices, 2)}
                    //                value={data.questions[inx].hasOwnProperty('answer') ? data.questions[inx]['answer'] : ''}
                    //                onslideChange={onTextChange, inx}
                    //
                    // />
                    }


                    {data.questions[inx].answerType === "Long text response" &&
                    <TextArea type="input" name={inx}
                              value={data.questions[inx].hasOwnProperty('answer') ? data.questions[inx]['answer'] : ''}
                              onTextChange={(e) => onTextChange(e, inx)}/>
                    }


                    {data.questions[inx].answerType === "Text response" &&
                    <InputBox type="input" name={inx}
                              value={data.questions[inx].hasOwnProperty('answer') ? data.questions[inx]['answer'] : ''}
                              onTextChange={(e) => onTextChange(e, inx)}/>
                    }
                    {data.questions[inx].answerType === "Single Choice" &&
                    // <ReviewElement type="radio" name={inx} data={changeToarray(data.questions[inx].answerChoices)}
                    //                handleRadio={onTextChange}/>

                        <RadioBlock
                            name={inx}
                        value={data.questions[inx].hasOwnProperty('answer') ? data.questions[inx]['answer'] : ''}
                        data={changeToarray(data.questions[inx].answerChoices)}
                        handleRadio={(e) => onTextChange(e, inx)}
                        typ={'q'}

                        />



                    }
                    {data.questions[inx].answerType === "Multiple choice" &&
                    // <ReviewElement type="checkbox" name={inx} data={changeToarray(data.questions[inx].answerChoices)}
                    //                handleCheckbox={handleCheckbox}/>

                        <CheckboxBlock  name={inx}
                                        data={changeToarray(data.questions[inx].answerChoices)}
                                        value={data.questions[inx].hasOwnProperty('answer') ? data.questions[inx]['answer'] : ''}
                                        handleCheckbox={handleCheckbox}/>

                    }
                    {((data.questions[inx].answer === data.questions[inx].remark1Trigger) || (checkRemarksStatus(data,inx,data.questions[inx].answer, 1)))  &&

                        <div className='remark-block'>
                            <div className='remark-question'>
                                {data.questions[inx].remark1Text}
                            </div>

                            {
                                data.questions[inx].remark1Type === "Text response" &&

                                <InputBox type="input" name={inx}
                                          value={data.questions[inx].hasOwnProperty('remark_1') ? data.questions[inx]['remark_1'] : ''}
                                          onTextChange={(e) => onRemarkTextChange(e, 1, inx)}/>
                            }

                            {
                                data.questions[inx].remark1Type === "Single Choice" &&

                                <RadioBlock name={inx}
                                            value={data.questions[inx].hasOwnProperty('remark_1') ? data.questions[inx]['remark_1'] : ''}
                                            data={changeToarray(data.questions[inx].remark1AnswerChoice)}
                                            typ={'r1'}
                                            handleRadio={(e) => onRemarkTextChange(e, 1, inx)}

                                />


                                // <ReviewElement type="radio" name={inx}
                                //                 data={changeToarray(data.questions[inx].remark1AnswerChoice)}
                                //                handleRadio={(e) => onRemarkTextChange(e, 1, inx)}
                                // />
                            }

                            {
                                data.questions[inx].remark1Type === "Long text response" &&

                                <TextArea type="input" name={inx}
                                          value={data.questions[inx].hasOwnProperty('remark_1') ? data.questions[inx]['remark_1'] : ''}
                                          onTextChange={(e) => onRemarkTextChange(e, 1, inx)}/>
                            }

                            {
                                data.questions[inx].remark1Type === "Slider Scale" &&
                                <RangeSlider    type="range" name={inx}
                                                start_text={changeToSlider(data.questions[inx].remark1AnswerChoice, 0)}
                                                end_text={changeToSlider(data.questions[inx].remark1AnswerChoice, 1)}
                                                max_val={changeToSlider(data.questions[inx].remark1AnswerChoice, 2)}
                                                value={data.questions[inx].hasOwnProperty('remark_1') ? data.questions[inx]['remark_1'] : ''}
                                                onslideChange={(e) => onRemarkTextChange(e, 1, inx)}
                                />


                            }

                            {
                                data.questions[inx].remark1Type === "Multiple choice" &&

                                <CheckboxBlock  name={inx}
                                                data={changeToarray(data.questions[inx].remark1AnswerChoice)}
                                                value={data.questions[inx].hasOwnProperty('remark_1') ? data.questions[inx]['remark_1'] : ''}
                                                handleCheckbox={(n, v) => onRemarkHandleCheckbox(n, v, 1)}/>

                            }

                        </div>
                    }
                    {((data.questions[inx].answer === data.questions[inx].remark2Trigger) || (checkRemarksStatus(data,inx,data.questions[inx].answer, 2)))  &&

                        <div className='remark-block'>
                            <div className='remark-question'>
                                {data.questions[inx].remark2Text}
                            </div>

                            {
                                data.questions[inx].remark2Type === "Text response" &&

                                <InputBox type="input" name={inx}
                                          value={data.questions[inx].hasOwnProperty('remark_2') ? data.questions[inx]['remark_2'] : ''}
                                          onTextChange={(e) => onRemarkTextChange(e, 2, inx)}/>
                            }

                            {
                                data.questions[inx].remark2Type === "Single Choice" &&

                                <RadioBlock name={inx}
                                            value={data.questions[inx].hasOwnProperty('remark_2') ? data.questions[inx]['remark_2'] : ''}
                                            data={changeToarray(data.questions[inx].remark2AnswerChoice)}
                                            typ={'r2'}
                                            handleRadio={(e) => onRemarkTextChange(e, 2, inx)}

                                />
                            }

                            {
                                data.questions[inx].remark2Type === "Long text response" &&

                                <TextArea type="input" name={inx}
                                          value={data.questions[inx].hasOwnProperty('remark_2') ? data.questions[inx]['remark_2'] : ''}
                                          onTextChange={(e) => onRemarkTextChange(e, 2, inx)}/>
                            }

                            {
                                data.questions[inx].remark2Type === "Slider Scale" &&

                                <RangeSlider    type="range" name={inx}
                                                start_text={changeToSlider(data.questions[inx].remark2AnswerChoice, 0)}
                                                end_text={changeToSlider(data.questions[inx].remark2AnswerChoice, 1)}
                                                max_val={changeToSlider(data.questions[inx].remark2AnswerChoice, 2)}
                                                value={data.questions[inx].hasOwnProperty('remark_2') ? data.questions[inx]['remark_2'] : ''}
                                                onslideChange={(e) => onRemarkTextChange(e, 2, inx)}
                                />


                                // <ReviewElement
                                // type="range" name={inx}
                                //                start_text={changeToSlider(data.questions[inx].remark2AnswerChoice, 0)}
                                //                end_text={changeToSlider(data.questions[inx].remark2AnswerChoice, 1)}
                                //                max_val={changeToSlider(data.questions[inx].remark2AnswerChoice, 2)}
                                //                value={data.questions[inx].hasOwnProperty('remark_2') ? data.questions[inx]['remark_2'] : ''}
                                //                onslideChange={(e) => onRemarkTextChange(e, 2, inx)}
                                //
                                // />
                            }

                            {
                                data.questions[inx].remark2Type === "Multiple choice" &&

                                <CheckboxBlock  name={inx}
                                                data={changeToarray(data.questions[inx].remark2AnswerChoice)}
                                                value={data.questions[inx].hasOwnProperty('remark_2') ? data.questions[inx]['remark_2'] : ''}
                                                handleCheckbox={(n, v) => onRemarkHandleCheckbox(n, v, 2)}/>

                            }


                        </div>
                    }

                    {((data.questions[inx].remark_1 === data.questions[inx].remark3Trigger) || (checkRemarksStatus(data,inx,data.questions[inx].remark_1, 3)))  &&

                         <div className='remark-block'>
                            <div className='remark-question'>
                                {data.questions[inx].remark3Text}
                            </div>

                            {
                                data.questions[inx].remark3Type === "Text response" &&

                                <InputBox type="input" name={inx}
                                          value={data.questions[inx].hasOwnProperty('remark_3') ? data.questions[inx]['remark_3'] : ''}
                                          onTextChange={(e) => onRemarkTextChange(e, 3, inx)}/>
                            }

                            {
                                data.questions[inx].remark3Type === "Single Choice" &&

                                <RadioBlock name={inx}
                                            value={data.questions[inx].hasOwnProperty('remark_3') ? data.questions[inx]['remark_3'] : ''}
                                            data={changeToarray(data.questions[inx].remark3AnswerChoice)}
                                            typ={'r3'}
                                            handleRadio={(e) => onRemarkTextChange(e, 3, inx)}

                                />
                            }

                            {
                                data.questions[inx].remark3Type === "Long text response" &&

                                <TextArea type="input" name={inx}
                                          value={data.questions[inx].hasOwnProperty('remark_3') ? data.questions[inx]['remark_3'] : ''}
                                          onTextChange={(e) => onRemarkTextChange(e, 3, inx)}/>
                            }

                            {
                                data.questions[inx].remark3Type === "Slider Scale" &&

                                <RangeSlider    type="range" name={inx}
                                                start_text={changeToSlider(data.questions[inx].remark3AnswerChoice, 0)}
                                                end_text={changeToSlider(data.questions[inx].remark3AnswerChoice, 1)}
                                                max_val={changeToSlider(data.questions[inx].remark3AnswerChoice, 2)}
                                                value={data.questions[inx].hasOwnProperty('remark_3') ? data.questions[inx]['remark_3'] : ''}
                                                onslideChange={(e) => onRemarkTextChange(e, 3, inx)}
                                />


                                // <ReviewElement
                                // type="range" name={inx}
                                //                start_text={changeToSlider(data.questions[inx].remark2AnswerChoice, 0)}
                                //                end_text={changeToSlider(data.questions[inx].remark2AnswerChoice, 1)}
                                //                max_val={changeToSlider(data.questions[inx].remark2AnswerChoice, 2)}
                                //                value={data.questions[inx].hasOwnProperty('remark_2') ? data.questions[inx]['remark_2'] : ''}
                                //                onslideChange={(e) => onRemarkTextChange(e, 2, inx)}
                                //
                                // />
                            }

                            {
                                data.questions[inx].remark3Type === "Multiple choice" &&

                                <CheckboxBlock  name={inx}
                                                data={changeToarray(data.questions[inx].remark3AnswerChoice)}
                                                value={data.questions[inx].hasOwnProperty('remark_3') ? data.questions[inx]['remark_3'] : ''}
                                                handleCheckbox={(n, v) => onRemarkHandleCheckbox(n, v, 3)}/>

                            }


                         </div>
                    }

                    {
                        mandatoryMsg !== '' &&

                        <div className='mandatory-txt'>{mandatoryMsg}</div>
                    }

                    {data.questions.length > (inx + 1) &&
                        <div className='formBtnWrap'>

                            {inx !== 0 ?
                            <span className='btn btn--outer' onClick={backClick}>Back</span>
                                :
                                <span></span>
                            }
                            <span className='btn btn--secondary' onClick={nextClick}>Next</span>
                        </div>
                    }

                    {
                        data.questions.length === (inx + 1) && (
                            <div className='formBtnWrap'>
                                {inx !== 0 &&
                                <span className='btn btn--outer' onClick={() => previousClick(inx - 1)}>Back</span>
                                }
                                <span className='btn btn--secondary' onClick={() => handleSubmit(true)}>Submit</span>
                            </div>
                        )
                    }
                </div>



                }



            </>
            }


            {sd.submitted && sd.code === 200 &&  sd.status === "Success" &&
                <h2 className='tqMessgae'>{data.surveyThankyouMessage}</h2>
            }
            {sd.submitted && sd.code !== 200 &&
            <h2 className='tqMessgae'>Communication lost. Please try again after some time</h2>
            }
            {sd.submitted && sd.code === 200 && sd.status === "InvalidSignature" &&
            <h2 className='tqMessgae'>
                Your submission could not be processed at this time due to an issue with your session. Please refresh your page and try again. If the issue persists, please contact your Club venue support team!
            </h2>
            }
            {sd.submitted && sd.code === 200 && sd.status === "AlreadySubmitted" &&
            <h2 className='tqMessgae'>
                Thank you for your participation! We have already received your responses for this survey. Your opinions matter to us and we appreciate your time.
            </h2>
            }


            {sd.submitted && sd.code === 200 && sd.status === "ZynapseFailed" &&
            <h2 className='tqMessgae'>
                Communication lost. Please try again after some time
            </h2>
            }

            {data.status ==="InvalidQlistID" &&
            <h2 className='tqMessgae'>Unable to start your survey, please contact your Club venue support team!</h2>

            }


            {data.status === "InvalidSignature" &&
               <h2 className='tqMessgae'>Unable to start your survey, please contact your Club venue support team!</h2>

            }
            {data.status === "Expired" &&
            <h2 className='tqMessgae'>The survey has expired. Thank you for your interest in participating.</h2>

            }

            {data.status === "AlreadySubmitted" &&
            <h2 className='tqMessgae'>Thank you for your participation! We have already received your responses for this survey. Your opinions matter to us and we appreciate your time.</h2>

            }
            {data.status === "Failed" &&
            <h2 className='tqMessgae'> Communication lost. Please try again after some time.</h2>

            }

        </>
    )
}

export default SurveyHolder
