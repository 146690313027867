import axios from 'axios';
import {ES_INDEX,API_PATH} from "../config";

export const commonService = {
    sendHttpRequest,
};

function sendHttpRequest(URL, data, method) {
    var date = new Date();
    var timestamp = date.getTime();
console.log(ES_INDEX)
   data.index = ES_INDEX;
    let param = '';
    let statikKey = 'VVRKR2JGcHRaREJPUkZVeFRsUlVUSkdiRnB0WkRCT1JGVXhUbFJGZUU1cVZXaE5la2t3VGxoS2IxcEhXbTVqZWxWNlRrUldaVXBwY0dWTFExbHdTME5WYkZvelRtdGFNMDVyV25rMWJHUXlWbkZoTW1Sb1lUTktiMWxSUFQwPUZlRTVxVldoTmVra3dUbGhLYjFwSFdtNWplbFY2VGtSV1pVcHBjR1ZMUTFsd1MwTlZiRm96VG10YU0wNXJXbmsxYkdReVZuRmhNbVJvWVROS2IxbFJQVDA9PT0';
    return axios({
        method: method,
        url: API_PATH+ URL + '?t=' + timestamp + '&index=' + ES_INDEX,
        data: method === 'POST' ?data:"",
        params: method === 'GET' ?data:"",
        headers : {'Content-Type' : 'application/json','X_API_KEY':statikKey},

        async: false,
    })
        .then(function (res) {
            return {
                msg: '',
                code: res.status,
                res: res.data,
            };
        })
        .catch(function (error) {
            console.log(error);
            if (error.response) {
                if (
                    error.response.status === 401 &&
                    error.response.data.error.message === 'Unauthorized'
                ) {
                    param = {
                        msg: 'Your user session has expired. Click ok to relogin.',
                        code: 401,
                        res: '',
                    };
                }
                if (error.response.status === 500) {
                    param = {
                        msg: 'Something Went wrong.',
                        code: error.response.status,
                        res: '',
                    };
                }
            } else {
                param = {
                    msg: 'There is a network failure, please try again.',
                    code: '12023',
                    res: '',
                };
            }

            return param;
        });
}



export function getSubdomain(hostname) {
    var regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
    var urlParts = regexParse.exec(hostname);
    return hostname.replace(urlParts[0], '').slice(0, -1);
}
