import React, {useEffect, useState} from 'react';
import Sidebar from './components/Sidebar';

import preloader from './assets/images/pre-loader.png';

import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import SurveyHolder from "./SurveyHolder";
import {form} from "./service/form.services";
import {jwtDecode} from "jwt-decode";

const App = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const [data, setData] = useState({attachment:"",logp:"",backgroundImage:"",questions:[],surveyID:"",surveySubtitle:"",surveyTitle:"",status:''});
    const [loader, setLoader] = useState(true)
    const [id, setID] = useState(0)
    const [initErr, setInitErr] = useState('')
    const [showWelcomeMsg, setShowWelcomeMsg] = useState(false)

    useEffect(() => {
        loadSurvey()
        //loadMix()
    }, []);
    function loadSurvey(){
        setLoader(true)
        let param={
            "token":token,
        }
        if(token === null){
            setData(undefined)
        }
        else {
            form.listService(param).then(function (res) {
                // console.log(res.res)
                setData(res.res)
                setLoader(false)

                // console.log(jwtDecode(token).fname)
                // console.log(res.res.welcomeMessage)

                if(res.res.welcomeMessage !== undefined && res.res.welcomeMessage !== '' && res.res.status === 'Success'){
                    setShowWelcomeMsg(true)
                    }

                if(res.res.favIcon !== undefined && res.res.favIcon !== ''){
                    let link = document.querySelector("link[rel~='icon']");
                    if (!link) {
                      link = document.createElement('link');
                      link.rel = 'icon';
                      document.getElementsByTagName('head')[0].appendChild(link);
                        }
                    link.href = res.res.favIcon;
                    }

                if (res.res === '') {
                    setInitErr('Sorry, unable to start the survey now. Please try again after sometime.')
                }

            })
        }
    }

    function getThemeColor(){
        if( data === undefined || data.surveyTheme === undefined || data.surveyTheme === ''){
                return '#9361fa'
            }
        else{
            return '#'+data.surveyTheme
            }
        // console.log(data.surveyTheme)

        }

    function startQuiz(){
        setShowWelcomeMsg(false)
        }

    return (
        <Router>
            <div className='reviewBlock' style={{'--primary':getThemeColor(), '--secondary':getThemeColor()}}>
                {token !== null &&
                <Sidebar data={data} loader={loader} id={id} token={token} showWelcomeMsg={showWelcomeMsg} />
                }
                {loader &&
                <div className="preLoader">
                    <img src={preloader} alt="preloader"/>
                </div>
                }

                <div className='sidebarOffset'>
                    {

                        initErr !== '' && token !== null &&

                        <h2 className='tqMessgae'>{initErr}</h2>

                    }

                    {
                        showWelcomeMsg === true &&

                        <>
                            <h2 className='welcomeMessgae' dangerouslySetInnerHTML={{ __html: data.welcomeMessage.split('|||first_name|||').join(jwtDecode(token).fname) }} ></h2>
                            <div className='formBtnWrap'>
                                <span></span>
                                <span className='btn btn--secondary' onClick={startQuiz} >START</span>
                            </div>
                        </>
                    }

                    {
                        showWelcomeMsg === false &&

                        <Routes>
                            {

                                token !== null &&
                                <Route path='/' exact element={<SurveyHolder data={data} setID={setID}/>} />
                            }

                            {token === null &&
                            <Route path='/' exact element={ <div className="preLoader">
                                <img src={preloader} alt="preloader"/>
                                <h2 className='tqMessgae'>{'Thank you for your interest in participating'}</h2>

                            </div>}/>
                            }

                        </Routes>
                    }

                </div>
            </div>
        </Router>
    )
}

export default App;
